import styled from 'styled-components';

export const HeaderMenuBar = styled.div` display:-webkit-box;
-webkit-box-orient: horizontal;
-webkit-box-flex:1;
  
display:-moz-box;
-moz-box-orient: horizontal;
-moz-box-flex:1;
  
display:-o-box;
-o-box-orient:horizontal;
-o-box-flex:1;

display: -ms-flexbox;
-ms-flex-direction: row;
flex-direction: row;

display: flex;
display:-webkit-flex;
flex-direction: row;

height: 90%;
text-align: center;
align-items: center;
justify-content: center;
display:none;

@media (max-width: 1165px){
  display: flex;
  display:-webkit-flex;
  flex-direction: row;
  background:#000533;
  width: 10%;
  height: 100%;
}`

export const HeaderMenuBarImg = styled.img`display:-webkit-box;
-webkit-box-orient: horizontal;
-webkit-box-flex:1;
  
display:-moz-box;
-moz-box-orient: horizontal;
-moz-box-flex:1;
  
display:-o-box;
-o-box-orient:horizontal;
-o-box-flex:1;

display: -ms-flexbox;
-ms-flex-direction: row;
flex-direction: row;

width:100%;
height: 90%;

width:70%;
height:70%;`
