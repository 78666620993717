const defaultState = {
  category: [],
  retrievedCategory: [],
  currentCategory: '', 
  errorMessage: '',
  blogStructure: [],                                                                    
};                            

const categoryReducer = (state = defaultState, action) => {
  switch(action.type){
    case 'SET_CATEGORY_STATE':
      return {
        ...state,                              
        category : action.category
      };
    case 'SET_RETRIEVED_CATEGORY':
      return {
        ...state,                              
        retrievedCategory : action.retrievedCategory
      };
    case 'SET_CURRENT_CATEGORY':
      return {
        ...state,                              
        currentCategory : action.currentCategory
      };
    case 'SET_ERROR_MESSAGE':
      return {
        ...state,                              
        errorMessage : action.errorMessage
      };
    case 'SET_BLOG_STRUCTURE':
      return {
        ...state,                              
        blogStructure : action.blogStructure
      };
    default:
      return state;
  };
};

export default categoryReducer;