import styled from 'styled-components';

export const FooterDiv = styled.div` display:-webkit-box;
-webkit-box-orient: horizontal;
-webkit-box-flex:1;
    
display:-moz-box;
-moz-box-orient: horizontal;
-moz-box-flex:1;
    
display:-o-box;
-o-box-orient:horizontal;
-o-box-flex:1;

display: -ms-flexbox;
-ms-flex-direction: row;
flex-direction: row;

display: flex;
display:-webkit-flex;
flex-direction: row;

height: 5rem;
border-top: 1px solid rgba(0,0,0, 0.09);
background: #fff;

@media (max-width:923px){
  display:-webkit-box;
    -webkit-box-orient: vertical;
    -webkit-box-flex:1;
        
    display:-moz-box;
    -moz-box-orient: vertical;
    -moz-box-flex:1;
        
    display:-o-box;
    -o-box-orient:vertical;
    -o-box-flex:1;
  
    display: -ms-flexbox;
    -ms-flex-direction: column;
    flex-direction: column;
  
    display: flex;
    display:-webkit-flex;
    flex-direction: column;
  
    height: 10rem;
    border-top: 1px solid rgba(0,0,0, 0.09);  
}`

export const FooterDivCopy = styled.div`display:-webkit-box;
-webkit-box-orient: horizontal;
-webkit-box-flex:1;
    
display:-moz-box;
-moz-box-orient: horizontal;
-moz-box-flex:1;
    
display:-o-box;
-o-box-orient:horizontal;
-o-box-flex:1;

display: -ms-flexbox;
-ms-flex-direction: row;
flex-direction: row;

display: flex;
display:-webkit-flex;
flex-direction: row;
width: 50%;

text-align: center;
justify-content: center;
align-items: center;

@media (max-width:923px){
  width: 100%;
  height: 50%;
  background: #ffffff;
}`

export const FooterDivSocial = styled.div`display:-webkit-box;
-webkit-box-orient: horizontal;
-webkit-box-flex:1;
    
display:-moz-box;
-moz-box-orient: horizontal;
-moz-box-flex:1;
    
display:-o-box;
-o-box-orient:horizontal;
-o-box-flex:1;

display: -ms-flexbox;
-ms-flex-direction: row;
flex-direction: row;

display: flex;
display:-webkit-flex;
flex-direction: row;
width: 50%;

text-align: center;
justify-content: center;
align-items: center;

@media (max-width:923px){
  width: 100%;
  height: 50%;
  background: #ffffff;
}`