const defaultState = {
  projectOneState: false,
  projectTwoState: false,
  showRoomOneState: false,
  showRoomTwoState: false,
  blogModalStatus: undefined,
  interiorAll: true,
  interiorArchi: false,
  interiorInt: false,
  interiorUrban: false,
  popceiling: false,
  privateResidence: false,
  apartment: false,
  wallpanel:false,
  wallframe:false,
  upcoming: false,
  smallMenuState: false,
  menuFacilityMgt: false,
  menuInterior: false,
  menuShopAll: false,
  menuLocation: false,
  menuContact: false,
  tssMore: false,
  allServices: false,
  plumberServices: false,
  electricalServices: false,
  acServices:false,
  handymanServices: false,
  homeImprovement: false,
  housekeeping: false,
  generator: false,
  move: false,
  landscape: false,
  watertank: false,
  pestcontrol: false,
  painting: false,
  details: false,
  srFurniture: false,
  srToiletries: false,
  srKitchen: false,
  srAppliances: false,
  workspace: false,
  commercial: false,
  hospital: false,
  hospitality: false,
};

const allCSSReducer = (state = defaultState, action) => {
  switch(action.type){
    case 'SET_PROJECTONE_STATE':
      return {
        ...state,
        projectOneState: action.projectOneState,
      };
    case 'SET_PROJECTTWO_STATE':
      return {
        ...state,
        projectTwoState: action.projectTwoState,
      };
    case 'SET_SHOWROOMONE_STATE':
      return {
        ...state,
        showRoomOneState: action.showRoomOneState,
      };
    case 'SET_SHOWROOMTWO_STATE':
      return {
        ...state,
        showRoomTwoState: action.showRoomTwoState,
      };
    case 'SET_BLOG_MODAL_STATE':
      return {
        ...state,
        modalStatus : action.blogModalStatus
      };
    case 'SET_INTERIOR_ALL_STATE':
      return {
        ...state,
        interiorAll : action.interiorAll
      };
    case 'SET_INTERIOR_ARCHI_STATE':
      return {
        ...state,
        interiorArchi : action.interiorArchi
      };
    case 'SET_INTERIOR_INT_STATE':
      return {
        ...state,
        interiorInt : action.interiorInt
      };
    case 'SET_INTERIOR_URBAN_STATE':
      return {
        ...state,
        interiorUrban : action.interiorUrban
      };
    case 'SET_PRIVATE_RESIDENCE_STATE':
      return {
        ...state,
        privateResidence : action.privateResidence
      };
    case 'SET_APARTMENT_STATE':
      return {
        ...state,
        apartment : action.apartment
      };
    case 'SET_POPCEILING_STATE':
      return {
        ...state,
        popceiling : action.popceiling
      };
    case 'SET_WALLPANEL_STATE':
      return {
        ...state,
        wallpanel : action.wallpanel
      };
    case 'SET_WALLFRAME_STATE':
      return {
        ...state,
        wallframe : action.wallframe
      };
    case 'SET_UPCOMING_STATE':
      return {
        ...state,
        upcoming : action.upcoming
      };
    case 'SET_SMALLMENU_STATE':
      return {
        ...state,
        smallMenuState : action.smallMenuState
      };
    case 'SET_MENU_INTERIOR_STATE':
      return {
        ...state,
        menuInterior : action.menuInterior
      };
    case 'SET_MENU_SHOWROOM_STATE':
      return {
        ...state,
        menuShopAll : action.menuShopAll
      };
    case 'SET_TSSMORE_STATE':
      return {
        ...state,
        tssMore : action.tssMore
      };
    case 'SET_MENU_LOCATION_STATE':
      return {
        ...state,
        menuLocation : action.menuLocation
      };
    case 'SET_MENU_CONTACT_STATE':
      return {
        ...state,
        menuContact : action.menuContact
      };
    case 'SET_ALL_SERVICES_STATE':
      return {
        ...state,
        allServices : action.allServices
      };
    case 'SET_PLUMBER_SERVICES_STATE':
      return {
        ...state,
        plumberServices : action.plumberServices
      };
    case 'SET_ELECTRICAL_SERVICES_STATE':
      return {
        ...state,
        electricalServices : action.electricalServices
      };
    case 'SET_AC_SERVICES_STATE':
      return {
        ...state,
        acServices : action.acServices
      };
    case 'SET_HANDYMAN_SERVICES_STATE':
      return {
        ...state,
        handymanServices : action.handymanServices
      };
    case 'SET_HOME_IMPROVEMENT_STATE':
      return {
        ...state,
        homeImprovement : action.homeImprovement
      };
    case 'SET_MOVE_STATE':
      return {
        ...state,
        move : action.move
      };
    case 'SET_LANDSCAPE_STATE':
      return {
        ...state,
        landscape : action.landscape
      };
    case 'SET_WATERTANK_STATE':
      return {
        ...state,
        watertank : action.watertank
      };
    case 'SET_PESTCONTROL_STATE':
    return {
      ...state,
      pestcontrol : action.pestcontrol
    };
    case 'SET_HOUSEKEEPING_STATE':
      return {
        ...state,
        housekeeping : action.housekeeping
      };
    case 'SET_GENERATOR_STATE':
      return {
        ...state,
        generator : action.generator
      };
    case 'SET_PAINTING_STATE':
    return {
      ...state,
      painting : action.painting
    };
  case 'SET_DETAILS_STATE':
    return {
      ...state,
      details : action.details
    };
  case 'SET_FURNITURE_STATE':
    return {
      ...state,
      srFurniture : action.srFurniture
    };
  case 'SET_TOILETRIES_STATE':
    return {
      ...state,
      srToiletries : action.srToiletries
    };
  case 'SET_KITCHEN_STATE':
    return {
      ...state,
      srKitchen : action.srKitchen
    };
  case 'SET_APPLIANCES_STATE':
    return {
      ...state,
      srAppliances : action.srAppliances
    };
  case 'SET_WORKSPACE_STATE':
    return {
      ...state,
      workspace : action.workspace
    };
  case 'SET_COMMERCIAL_STATE':
    return {
      ...state,
      commercial : action.commercial
    };
  case 'SET_HOSPITAL_STATE':
    return {
      ...state,
      hospital : action.hospital
    };
  case 'SET_HOSPITALITY_STATE':
    return {
      ...state,
      hospitality : action.hospitality
    };
    default:
      return state;
  };
};

export default allCSSReducer;
