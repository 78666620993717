import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './depend/reportWebVitals';
import { Provider } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import AppRouter from './routers/AppRouter';
import 'react-toastify/dist/ReactToastify.css';
import configureStore from './store/configureStore';
import 'normalize.css/normalize.css';
import "react-multi-carousel/lib/styles.css";
import 'core-js/stable/map';
import 'core-js/stable/set';
import './styles/styles.scss';
import './styles/base/base.scss';
import './styles/base/settings.scss';
import './styless/index.css';

const store = configureStore();
// provider makes the store available everywhere in the application
const Appp = (
  <Provider store={store}>
    <AppRouter />
    <ToastContainer autoClose={4000} pauseOnFocusLoss={false} position={toast.POSITION.TOP_CENTER}/>
  </Provider>
);

ReactDOM.render(Appp, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();