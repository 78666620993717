const defaultState = {
  verify: false,
  phoneNum: 'Yet to verify staff.',
  currentStaff: '',
  imageSet: false,
  feedbackColor: 'red', 
  pictureStr: ''                                                                     
};                            

const verifyReducer = (state = defaultState, action) => {
  switch(action.type){
    case 'SET_VERIFY_STATE':
      return {
        ...state,                              
        verify : action.verify
      };
    case 'SET_PHONE_NUMBER':
      return {
        ...state,
        phoneNum: action.phoneNum,
      };
    case 'SET_STAFF_IDENTIFY':
      return {
        ...state,
        currentStaff: action.currentStaff,
      };
    case 'SET_IMAGE_SET':
      return {
        ...state,
        imageSet: action.imageSet,
      };
    case 'SET_PICTURE_STR':
      return {
        ...state,
        pictureStr: action.pictureStr,
      };
    case 'SET_FEEDBACK_COLOR':
      return {
        ...state,
        feedbackColor: action.feedbackColor,
      };
    default:
      return state;
  };
};

export default verifyReducer;
