import styled from 'styled-components';

export const HeaderLogo = styled.div` display:-webkit-box;
-webkit-box-orient: horizontal;
-webkit-box-flex:1;
  
display:-moz-box;
-moz-box-orient: horizontal;
-moz-box-flex:1;
  
display:-o-box;
-o-box-orient:horizontal;
-o-box-flex:1;

display: -ms-flexbox;
-ms-flex-direction: row;
flex-direction: row;

display: flex;
display:-webkit-flex;
flex-direction: row;

width:15%;
height: 100%;
text-align: center;
align-items: center;
justify-content: center;

@media (max-width: 1165px){
text-align:left;
justify-content:left;
align-items: left;
width:85%;
height:100%;
}`

export const HeaderLogoImage = styled.img` display:-webkit-box;
  display:-webkit-box;
  -webkit-box-orient: horizontal;
  -webkit-box-flex:1;
    
  display:-moz-box;
  -moz-box-orient: horizontal;
  -moz-box-flex:1;
    
  display:-o-box;
  -o-box-orient:horizontal;
  -o-box-flex:1;

  display: -ms-flexbox;
  -ms-flex-direction: row;
  flex-direction: row;
  width:100%;
  height: 100%;
  width: 401px;
  height: 107px;
  
  @media (max-width: 1165px){
    width: 30%;
    height:100%;
  }`