import styled from 'styled-components';

export const DisplayServices = styled.div` display:-webkit-box;
  -webkit-box-orient:vertical;
  -webkit-box-flex:1;

  display:-moz-box;
  -moz-box-orient:vertical;
  -moz-box-flex:1;

  display:-o-box;
  -o-box-orient:vertical;
  -o-box-flex:1;
  display: -ms-flexbox;
  -ms-flex-direction: column;
  flex-direction: column;

  display: flex;
  display:-webkit-flex;
  flex-direction: column;
  font-family: 'Playfair Display',"CenturyGothic","HelveticaNeue","Helvetica Neue",Helvetica,Arial,Tahoma,sans-serif;

  width:100%; 
  justify-content: center;
  align-items: center;`

  export const DisplayServices1 = styled.div` display:-webkit-box;
  -webkit-box-orient:vertical;
  -webkit-box-flex:1;

  display:-moz-box;
  -moz-box-orient:vertical;
  -moz-box-flex:1;

  display:-o-box;
  -o-box-orient:vertical;
  -o-box-flex:1;
  display: -ms-flexbox;
  -ms-flex-direction: column;
  flex-direction: column;

  display: flex;
  display:-webkit-flex;
  flex-direction: column;
  font-family: 'Playfair Display',"CenturyGothic","HelveticaNeue","Helvetica Neue",Helvetica,Arial,Tahoma,sans-serif;

  width:100%; 
  justify-content: center;
  align-items: center;`

export const DisplayServices2 = styled.div`display: flex;
  display:-webkit-flex;
  flex-wrap: wrap;
  margin-top:5%;
  margin-bottom:5%;
  justify-content:center;
  align-items:center;
  width:90%;`

export const DisplayList = styled.div`margin: 1rem;
  background: #ffffff;
  box-shadow: 2px 4px 25px rgba(0, 0, 0, .1);
  border-radius: 12px;
  overflow: hidden;
  transition: all .2s linear;
  padding-bottom: 0.43em;
  text-align: center;
  justify-content: center;
  align-items:center;
  &:hover{
  box-shadow: 2px 8px 45px rgba(0, 0, 0, .15);
  transform: translate3D(0, -2px, 0);
};
  @media (max-width: 1600px){
    flex: 1 1 calc(30% - 2rem);
  };
  @media (max-width: 1240px){
    flex: 1 1 calc(40% - 2rem);
  };
  @media screen and (max-width: 700px){
    flex: 1 1 calc(70% - 2rem);
  };
  @media screen and (max-width: 350px){
    flex: 1 1 calc(70% - 2rem);
  };`

  export const FacilityDiv = styled.div`display:-webkit-box;
  -webkit-box-orient:vertical;
  -webkit-box-flex:1;

  display:-moz-box;
  -moz-box-orient:vertical;
  -moz-box-flex:1;

  display:-o-box;
  -o-box-orient:vertical;
  -o-box-flex:1;
  display: -ms-flexbox;
  -ms-flex-direction: column;
  flex-direction: column;

  display: flex;
  display:-webkit-flex;
  flex-direction: column;
  font-family: 'Playfair Display',"CenturyGothic","HelveticaNeue","Helvetica Neue",Helvetica,Arial,Tahoma,sans-serif;

  width:100%; 
  justify-content: center;
  align-items: center;
  z-index:10000`

