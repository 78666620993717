import styled from 'styled-components';

export const HomePage = styled.div` display:-webkit-box;
-webkit-box-orient: horizontal;
-webkit-box-flex:1;
  
display:-moz-box;
-moz-box-orient: horizontal;
-moz-box-flex:1;
  
display:-o-box;
-o-box-orient:horizontal;
-o-box-flex:1;

display: -ms-flexbox;
-ms-flex-direction: row;
flex-direction: row;

display: flex;
display:-webkit-flex;
flex-direction: row;

width:100%;
min-height: 100%;
position:absolute;
left: 0%;`