import styled from 'styled-components';

export const ProjectSubHead = styled.div`display:-webkit-box;
-webkit-box-orient: vertical;
-webkit-box-flex:1;
  
display:-moz-box;
-moz-box-orient: vertical;
-moz-box-flex:1;
  
display:-o-box;
-o-box-orient:vertical;
-o-box-flex:1;

display: -ms-flexbox;
-ms-flex-direction: column;
flex-direction: column;

display: flex;
display:-webkit-flex;
flex-direction: column;
text-align: left;
justify-content: left;
align-items: left;
font-family: 'Playfair Display',"CenturyGothic","HelveticaNeue","Helvetica Neue",Helvetica,Arial,Tahoma,sans-serif;

width:100%;
height: 10rem;
@media (max-width: 400px){
  height: 12rem;
}`

export const ProjectGallery = styled.div` display:-webkit-box;
-webkit-box-orient: horizontal;
-webkit-box-flex:1;
  
display:-moz-box;
-moz-box-orient: horizontal;
-moz-box-flex:1;
  
display:-o-box;
-o-box-orient:horizontal;
-o-box-flex:1;

display: -ms-flexbox;
-ms-flex-direction: row;
flex-direction: row;

display: flex;
display:-webkit-flex;
flex-direction: row;
text-align: left;
justify-content: left;
align-items: left;
margin-top: 2rem;
margin-bottom: 3rem;
font-family: 'Playfair Display',"CenturyGothic","HelveticaNeue","Helvetica Neue",Helvetica,Arial,Tahoma,sans-serif;

width:100%;
@media (max-width: 786px){
  -webkit-box-orient: vertical;
  -webkit-box-flex:1;
    
  display:-moz-box;
  -moz-box-orient: vertical;
  -moz-box-flex:1;
    
  display:-o-box;
  -o-box-orient:vertical;
  -o-box-flex:1;
  
  display: -ms-flexbox;
  -ms-flex-direction: column;
  flex-direction: column;
  
  display: flex;
  display:-webkit-flex;
  flex-direction: column;
}`

export const ProjectGallery1 = styled.div` display:-webkit-box;
-webkit-box-orient: verical;
-webkit-box-flex:1;
  
display:-moz-box;
-moz-box-orient: vertical;
-moz-box-flex:1;
  
display:-o-box;
-o-box-orient:vertical;
-o-box-flex:1;

display: -ms-flexbox;
-ms-flex-direction: column;
flex-direction: column;

display: flex;
display:-webkit-flex;
flex-direction: column;
text-align: left;
justify-content: left;
align-items: left;
font-family: 'Playfair Display',"CenturyGothic","HelveticaNeue","Helvetica Neue",Helvetica,Arial,Tahoma,sans-serif;

width:60%;
opacity: 1;
transition:width 4s, height 4s, opacity 4s 4s;
@media (max-width: 786px){
 width:100%;
}`


export const ProjectGallery2 = styled.div` display:-webkit-box;
-webkit-box-orient: vertical;
-webkit-box-flex:1;
  
display:-moz-box;
-moz-box-orient: vertical;
-moz-box-flex:1;
  
display:-o-box;
-o-box-orient:vertical;
-o-box-flex:1;

display: -ms-flexbox;
-ms-flex-direction: column;
flex-direction: column;

display: flex;
display:-webkit-flex;
flex-direction: column;
text-align: left;
justify-content: left;
align-items: left;
font-family: 'Playfair Display',"CenturyGothic","HelveticaNeue","Helvetica Neue",Helvetica,Arial,Tahoma,sans-serif;

width:40%;
@media (max-width: 786px){
 width:100%;
}`

export const ProjectListWrapper = styled.div`display: flex;
  display:-webkit-flex;
  flex-wrap: wrap;
  justify-content:center;
  align-items:center;
  width:95%;`

  export const ProjectLists = styled.div`margin: 1rem;
  background: #ffffff;
  box-shadow: 2px 4px 25px rgba(0, 0, 0, .1);
  border-radius: 12px;
  overflow: hidden;
  transition: all .2s linear;
  padding: 0.43em;
  text-align: center;
  justify-content: center;
  align-items:center;
  font-size: 0.54rem;
  &:hover{
  box-shadow: 2px 8px 45px rgba(0, 0, 0, .15);
  transform: translate3D(0, -2px, 0);
};
  @media (max-width: 1600px){
    flex: 1 1 calc(10% - 2rem);
  };
  @media (max-width: 1240px){
    flex: 1 1 calc(20% - 2rem);
  };
  @media screen and (max-width: 700px){
    flex: 1 1 calc(30% - 2rem);
  };
  @media screen and (max-width: 350px){
    flex: 1 1 calc(70% - 2rem);
  };`