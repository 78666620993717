import styled from 'styled-components';

export const DashBoard = styled.div` display:-webkit-box;
-webkit-box-orient: vertical;
-webkit-box-flex:1;
  
display:-moz-box;
-moz-box-orient: vertical;
-moz-box-flex:1;
  
display:-o-box;
-o-box-orient:vertical;
-o-box-flex:1;

display: -ms-flexbox;
-ms-flex-direction: column;
flex-direction: column;

display: flex;
display:-webkit-flex;
flex-direction: column;

width:100%;
min-height: 100%;`