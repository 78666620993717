import styled from 'styled-components';
import asset from '../../../img/asset.png'
import asset2 from '../../../img/asset2.png'

export const PricePlanDiv = styled.div`display:-webkit-box;
  -webkit-box-orient:horizontal;
  -webkit-box-pack:center;
  -webkit-box-flex:1;

  display:-moz-box;
  -moz-box-orient:horizontal;
  -moz-box-pack:center;
  -moz-box-flex:1;

  display:-o-box;
  -o-box-pack:center;
  -o-box-orient:horizontal;
  -o-box-flex:1;
  font-family: 'Playfair Display',"CenturyGothic","HelveticaNeue","Helvetica Neue",Helvetica,Arial,Tahoma,sans-serif;

  width:100%;
  height: 60rem;
  margin-bottom: 5%;

@media screen and (max-width: 1103px){
  -webkit-box-orient:vertical;
  -webkit-box-pack:center;
  -webkit-box-flex:1;

  display:-moz-box;
  -moz-box-orient:vertical;
  -moz-box-pack:center;
  -moz-box-flex:1;

  display:-o-box;
  -o-box-pack:center;
  -o-box-orient:vertical;

  height: 180rem;

  margin: 0;`

  export const PricePlanDiv1 = styled.div`display:-webkit-box;
  -webkit-box-orient:vertical;
  -webkit-box-pack:center;
  -webkit-box-flex:1;

  display:-moz-box;
  -moz-box-orient:vertical;
  -moz-box-pack:center;
  -moz-box-flex:1;

  display:-o-box;
  -o-box-pack:center;
  -o-box-orient:vertical;
  -o-box-flex:1;

  width: 30%;
  height: 90%;
  margin-left: 2%;
  margin-right: 2%;
  margin-top: 4%;

  background: #ffffff;
  box-shadow: 2px 4px 25px rgba(0, 0, 0, .1);
  border-radius: 12px;
  transition: all .2s linear;

  &:hover{
  box-shadow: 2px 8px 45px rgba(0, 0, 0, .15);
  transform: translate3D(0, -2px, 0);
};

@media screen and (max-width: 1103px){
  margin: 4%;
  width: 92%;
  height: 30%;`

  export const PricePlanDiv11 = styled.div` display:-webkit-box;
  -webkit-box-orient:vertical;
  -webkit-box-flex:1;

  display:-moz-box;
  -moz-box-orient:vertical;
  -moz-box-flex:1;

  display:-o-box;
  -o-box-orient:vertical;
  -o-box-flex:1;

  width: 100%;
  height: 30%;
  background-image: url(${asset});
  background-repeat: no-repeat;
  background-size: cover;
  background-repeat: no-repeat;
  background-position:center;
  color: #fff;

@media screen and (max-width: 754px){
  margin: 0;`

  export const PricePlanDiv12 = styled.div` display:-webkit-box;
  -webkit-box-orient:vertical;
  -webkit-box-flex:1;

  display:-moz-box;
  -moz-box-orient:vertical;
  -moz-box-flex:1;

  display:-o-box;
  -o-box-orient:vertical;
  -o-box-flex:1;
  width: 100%;
  height: 70%;


@media screen and (max-width: 754px){
  margin: 0;`

  export const PricePlanDiv2 = styled.div` display:-webkit-box;
  -webkit-box-orient:vertical;
  -webkit-box-flex:1;

  display:-moz-box;
  -moz-box-orient:vertical;
  -moz-box-flex:1;

  display:-o-box;
  -o-box-orient:vertical;
  -o-box-flex:1;
  margin: 2%;
  width: 30%;
  height: 95%;

  background: #ffffff;
  box-shadow: 2px 4px 25px rgba(0, 0, 0, .1);
  border-radius: 12px;
  transition: all .2s linear;

  &:hover{
  box-shadow: 2px 8px 45px rgba(0, 0, 0, .15);
  transform: translate3D(0, -2px, 0);
};


@media screen and (max-width: 1103px){
  margin: 4%;
  width: 92%;
  height: 30%;`

export const PricePlanDiv21 = styled.div` display:-webkit-box;
  -webkit-box-orient:vertical;
  -webkit-box-flex:1;

  display:-moz-box;
  -moz-box-orient:vertical;
  -moz-box-flex:1;

  display:-o-box;
  -o-box-orient:vertical;
  -o-box-flex:1;
  width: 100%;
  height: 30%;
  background-image: url(${asset2});
  background-repeat: no-repeat;
  background-size: cover;
  background-repeat: no-repeat;
  background-position:center;
  color: #fff;


@media screen and (max-width: 754px){
  margin: 0;`

export const PricePlanDiv22 = styled.div` display:-webkit-box;
  -webkit-box-orient:vertical;
  -webkit-box-flex:1;

  display:-moz-box;
  -moz-box-orient:vertical;
  -moz-box-flex:1;

  display:-o-box;
  -o-box-orient:vertical;
  -o-box-flex:1;
  width: 100%;
  height: 70%;


@media screen and (max-width: 754px){
  margin: 0;`

export const PricePlanDiv3 = styled.div` display:-webkit-box;
  -webkit-box-orient:vertical;
  -webkit-box-flex:1;

  display:-moz-box;
  -moz-box-orient:vertical;
  -moz-box-flex:1;

  display:-o-box;
  -o-box-orient:vertical;
  -o-box-flex:1;
  margin: 2%;
  width: 30%;
  height: 90%;
  margin-left: 2%;
  margin-right: 2%;
  margin-top: 4%;

  background: #ffffff;
  box-shadow: 2px 4px 25px rgba(0, 0, 0, .1);
  border-radius: 12px;
  transition: all .2s linear;

  &:hover{
  box-shadow: 2px 8px 45px rgba(0, 0, 0, .15);
  transform: translate3D(0, -2px, 0);
};


@media screen and (max-width: 1103px){
  margin: 4%;
  width: 92%;
  height: 30%;`

export const PricePlanDiv31 = styled.div` display:-webkit-box;
  -webkit-box-orient:vertical;
  -webkit-box-flex:1;

  display:-moz-box;
  -moz-box-orient:vertical;
  -moz-box-flex:1;

  display:-o-box;
  -o-box-orient:vertical;
  -o-box-flex:1;

  width: 100%;
  height: 30%;
  background-image: url(${asset});
  background-repeat: no-repeat;
  background-size: cover;
  background-repeat: no-repeat;
  background-position:center;
  color: #fff;


@media screen and (max-width: 754px){
  margin: 0;`

export const PricePlanDiv32 = styled.div` display:-webkit-box;
  -webkit-box-orient:vertical;
  -webkit-box-flex:1;

  display:-moz-box;
  -moz-box-orient:vertical;
  -moz-box-flex:1;

  display:-o-box;
  -o-box-orient:vertical;
  -o-box-flex:1;

  width: 100%;
  height: 70%;


@media screen and (max-width: 754px){
  margin: 0;`