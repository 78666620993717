import styled from 'styled-components';
import photo1 from '../../img/bannerphoto.png';

export const AboutBanner = styled.div`display:-webkit-box;
-webkit-box-orient: horizontal;
-webkit-box-flex:1;
  
display:-moz-box;
-moz-box-orient: horizontal;
-moz-box-flex:1;
  
display:-o-box;
-o-box-orient:horizontal;
-o-box-flex:1;

display: -ms-flexbox;
-ms-flex-direction: row;
flex-direction: row;

display: flex;
display:-webkit-flex;
flex-direction: row;

width:100%;
height:35rem;
background-image: url(${photo1});
background-repeat: no-repeat;
background-size: cover;
background-repeat: no-repeat;
background-position:center;
}`

export const AboutBannerDiv1 = styled.div`display:-webkit-box;
  -webkit-box-orient: vertical;
  -webkit-box-flex:1;
  
  display:-moz-box;
  -moz-box-orient: vertical;
  -moz-box-flex:1;
  
  display:-o-box;
  -o-box-orient:vertical;
  -o-box-flex:1;

  display: -ms-flexbox;
  -ms-flex-direction: column;
  flex-direction: column;

  display: flex;
  display:-webkit-flex;
  flex-direction: column;

  font-family: 'Playfair Display',"CenturyGothic","HelveticaNeue","Helvetica Neue",Helvetica,Arial,Tahoma,sans-serif;
  width:50%;
  height:100%;
  text-align:left;
  align-items:left;
  justify-content:center;
  margin-left: 7%;
}

@media (max-width: 754px){
  width:70%;
}`

export const AboutBannerDivTable = styled.table`display:-webkit-box;
  -webkit-box-flex:1;

  display:-moz-box;
  -moz-box-flex:1;

  display:-o-box;
  -o-box-flex:1;

  display: -ms-flexbox;

  display: flex;
  display:-webkit-flex;

  font-family: 'Playfair Display',"CenturyGothic","HelveticaNeue","Helvetica Neue",Helvetica,Arial,Tahoma,sans-serif;
  width: 100%;
  font-size: 0.87rem;
  color: #ffffff;
}`

export const AboutBannerTalk = styled.p`display:-webkit-box;
  -webkit-box-flex:1;

  display:-moz-box;
  -moz-box-flex:1;

  display:-o-box;
  -o-box-flex:1;

  display: -ms-flexbox;

  display: flex;
  display:-webkit-flex;
  font-family: 'Playfair Display',"CenturyGothic","HelveticaNeue","Helvetica Neue",Helvetica,Arial,Tahoma,sans-serif;
  width: 60%;
  color: #ffffff;
  opacity: .78;
  line-height: 1.6;
  font-weight: 200;
  word-break: break-word;
}`


export const AboutBannerDiv2 = styled.div`display:-webkit-box;
  -webkit-box-orient: horizontal;
  -webkit-box-flex:1;
  
  display:-moz-box;
  -moz-box-orient: horizontal;
  -moz-box-flex:1;
  
  display:-o-box;
  -o-box-orient:horizontal;
  -o-box-flex:1;

  display: -ms-flexbox;
  -ms-flex-direction: row;
  flex-direction: row;

  display: flex;
  display:-webkit-flex;
  flex-direction: row;

  font-family: 'Playfair Display',"CenturyGothic","HelveticaNeue","Helvetica Neue",Helvetica,Arial,Tahoma,sans-serif;
  width:100%;
  margin-top: 10%;
  margin-bottom:7%;
  
  @media (max-width: 756px){
    display:-webkit-box;
    -webkit-box-orient: vertical;
    -webkit-box-flex:1;
    
    display:-moz-box;
    -moz-box-orient: vertical;
    -moz-box-flex:1;
    
    display:-o-box;
    -o-box-orient:vertical;
    -o-box-flex:1;

    display: -ms-flexbox;
    -ms-flex-direction: column;
    flex-direction: column;

    display: flex;
    display:-webkit-flex;
    flex-direction: column;
  }`

export const AboutBannerDiv21 = styled.div`display:-webkit-box;
  -webkit-box-orient: vertical;
  -webkit-box-flex:1;
  
  display:-moz-box;
  -moz-box-orient: vertical;
  -moz-box-flex:1;
  
  display:-o-box;
  -o-box-orient:vertical;
  -o-box-flex:1;

  display: -ms-flexbox;
  -ms-flex-direction: column;
  flex-direction: column;

  display: flex;
  display:-webkit-flex;
  flex-direction: column;

  font-family: 'Playfair Display',"CenturyGothic","HelveticaNeue","Helvetica Neue",Helvetica,Arial,Tahoma,sans-serif;
  width:50%;
  text-align: left;
  justify-content:center;
  align-items:left;
  margin-left: 7%;
  line-height: 1.6;
  font-weight: 200;
  word-break: break-word;
  justify-content:start;
  align-items:start;
  
  @media (max-width: 756px){
    width:90%;
  }`

export const AboutBannerDiv22 = styled.div`display:-webkit-box;
  -webkit-box-orient: vertical;
  -webkit-box-flex:1;
  
  display:-moz-box;
  -moz-box-orient: vertical;
  -moz-box-flex:1;
  
  display:-o-box;
  -o-box-orient:vertical;
  -o-box-flex:1;

  display: -ms-flexbox;
  -ms-flex-direction: column;
  flex-direction: column;

  display: flex;
  display:-webkit-flex;
  flex-direction: column;

  font-family: 'Playfair Display',"CenturyGothic","HelveticaNeue","Helvetica Neue",Helvetica,Arial,Tahoma,sans-serif;
  width:50%;
  height:100%;

  @media (max-width: 756px){
    width:90%;
    margin-left: 7%;
  }`


  export const StoryDiv = styled.div`display:-webkit-box;
  -webkit-box-orient: horizontal;
  -webkit-box-flex:1;
  
  display:-moz-box;
  -moz-box-orient: horizontal;
  -moz-box-flex:1;
  
  display:-o-box;
  -o-box-orient:horizontal;
  -o-box-flex:1;

  display: -ms-flexbox;
  -ms-flex-direction: row;
  flex-direction: row;

  display: flex;
  display:-webkit-flex;
  flex-direction: row;

  font-family: 'Playfair Display',"CenturyGothic","HelveticaNeue","Helvetica Neue",Helvetica,Arial,Tahoma,sans-serif;
  width:100%;
  margin-bottom:7%;
  
  @media (max-width: 756px){
    display:-webkit-box;
    -webkit-box-orient: vertical;
    -webkit-box-flex:1;
    
    display:-moz-box;
    -moz-box-orient: vertical;
    -moz-box-flex:1;
    
    display:-o-box;
    -o-box-orient:vertical;
    -o-box-flex:1;

    display: -ms-flexbox;
    -ms-flex-direction: column;
    flex-direction: column;

    display: flex;
    display:-webkit-flex;
    flex-direction: column;
    width:100%;
  }`

  export const StoryDiv1 = styled.div`display:-webkit-box;
  -webkit-box-orient: horizontal;
  -webkit-box-flex:1;
  
  display:-moz-box;
  -moz-box-orient: horizontal;
  -moz-box-flex:1;
  
  display:-o-box;
  -o-box-orient:horizontal;
  -o-box-flex:1;

  display: -ms-flexbox;
  -ms-flex-direction: row;
  flex-direction: row;

  display: flex;
  display:-webkit-flex;
  flex-direction: row;

  width:40%;
  height:100%;
  
  @media (max-width: 756px){
    width:100%;
  }`

  export const StoryDiv2 = styled.div`display:-webkit-box;
  -webkit-box-orient: vertical;
  -webkit-box-flex:1;
  
  display:-moz-box;
  -moz-box-orient: vertical;
  -moz-box-flex:1;
  
  display:-o-box;
  -o-box-orient:vertical;
  -o-box-flex:1;

  display: -ms-flexbox;
  -ms-flex-direction: column;
  flex-direction: column;

  display: flex;
  display:-webkit-flex;
  flex-direction: column;

  font-family: 'Playfair Display',"CenturyGothic","HelveticaNeue","Helvetica Neue",Helvetica,Arial,Tahoma,sans-serif;
  width:60%;
  background: #242323;
  text-align:left;
  justify-content:left;
  align-items:left;
  padding-top:3%;
  padding-bottom:3%;
  
  @media (max-width: 756px){
    width:100%;
  }`


  export const AboutPeopleDiv = styled.div`display:-webkit-box;
  -webkit-box-orient: horizontal;
  -webkit-box-flex:1;
  
  display:-moz-box;
  -moz-box-orient: horizontal;
  -moz-box-flex:1;
  
  display:-o-box;
  -o-box-orient:horizontal;
  -o-box-flex:1;

  display: -ms-flexbox;
  -ms-flex-direction: row;
  flex-direction: row;

  display: flex;
  display:-webkit-flex;
  flex-direction: row;

  font-family: 'Playfair Display',"CenturyGothic","HelveticaNeue","Helvetica Neue",Helvetica,Arial,Tahoma,sans-serif;
  width:100%;
  margin-bottom:7%;
  justify-content: start;
  align-items: start;
  
  @media (max-width: 756px){
    display:-webkit-box;
    -webkit-box-orient: vertical;
    -webkit-box-flex:1;
    
    display:-moz-box;
    -moz-box-orient: vertical;
    -moz-box-flex:1;
    
    display:-o-box;
    -o-box-orient:vertical;
    -o-box-flex:1;

    display: -ms-flexbox;
    -ms-flex-direction: column;
    flex-direction: column;

    display: flex;
    display:-webkit-flex;
    flex-direction: column;
  }`

export const AboutPeopleDiv1 = styled.div`display:-webkit-box;
  -webkit-box-orient: vertical;
  -webkit-box-flex:1;
  
  display:-moz-box;
  -moz-box-orient: vertical;
  -moz-box-flex:1;
  
  display:-o-box;
  -o-box-orient:vertical;
  -o-box-flex:1;

  display: -ms-flexbox;
  -ms-flex-direction: column;
  flex-direction: column;

  display: flex;
  display:-webkit-flex;
  flex-direction: column;

  font-family: 'Playfair Display',"CenturyGothic","HelveticaNeue","Helvetica Neue",Helvetica,Arial,Tahoma,sans-serif;
  width:50%;
  text-align: left;
  justify-content:center;
  align-items:left;
  line-height: 1.6;
  font-weight: 200;
  word-break: break-word;
  
  @media (max-width: 756px){
    width:100%;
  }`

export const AboutPeopleDiv2 = styled.div`display:-webkit-box;
  -webkit-box-orient: vertical;
  -webkit-box-flex:1;
  
  display:-moz-box;
  -moz-box-orient: vertical;
  -moz-box-flex:1;
  
  display:-o-box;
  -o-box-orient:vertical;
  -o-box-flex:1;

  display: -ms-flexbox;
  -ms-flex-direction: column;
  flex-direction: column;

  display: flex;
  display:-webkit-flex;
  flex-direction: column;

  font-family: 'Playfair Display',"CenturyGothic","HelveticaNeue","Helvetica Neue",Helvetica,Arial,Tahoma,sans-serif;
  width:50%;
  height:100%;

  @media (max-width: 756px){
    width:100%;
  }`


  export const CommitmentDiv = styled.div`display:-webkit-box;
  -webkit-box-orient: vertical;
  -webkit-box-flex:1;
  
  display:-moz-box;
  -moz-box-orient: vertical;
  -moz-box-flex:1;
  
  display:-o-box;
  -o-box-orient:vertical;
  -o-box-flex:1;

  display: -ms-flexbox;
  -ms-flex-direction: column;
  flex-direction: column;

  display: flex;
  display:-webkit-flex;
  flex-direction: column;

  text-align: center;
  justify-content:center;
  align-items:center;
  font-family: 'Playfair Display',"CenturyGothic","HelveticaNeue","Helvetica Neue",Helvetica,Arial,Tahoma,sans-serif;
  width:100%;`

  export const CommitmentSlash = styled.div`display:-webkit-box;
  -webkit-box-orient: vertical;
  -webkit-box-flex:1;
  
  display:-moz-box;
  -moz-box-orient: vertical;
  -moz-box-flex:1;
  
  display:-o-box;
  -o-box-orient:vertical;
  -o-box-flex:1;

  display: -ms-flexbox;
  -ms-flex-direction: column;
  flex-direction: column;

  display: flex;
  display:-webkit-flex;
  flex-direction: column;

  
  width:85%;
  height:0.23rem;
  background: #181C4D;`

  export const CommitmentSlash2 = styled.div`display:-webkit-box;
  -webkit-box-orient: vertical;
  -webkit-box-flex:1;
  
  display:-moz-box;
  -moz-box-orient: vertical;
  -moz-box-flex:1;
  
  display:-o-box;
  -o-box-orient:vertical;
  -o-box-flex:1;

  display: -ms-flexbox;
  -ms-flex-direction: column;
  flex-direction: column;

  display: flex;
  display:-webkit-flex;
  flex-direction: column;

  
  width:40%;
  height:0.23rem;
  background: #181C4D;`

  
  export const CommitmentDiv1 = styled.div`display:-webkit-box;
  -webkit-box-orient: horizontal;
  -webkit-box-flex:1;
  
  display:-moz-box;
  -moz-box-orient: horizontal;
  -moz-box-flex:1;
  
  display:-o-box;
  -o-box-orient:horizontal;
  -o-box-flex:1;

  display: -ms-flexbox;
  -ms-flex-direction: row;
  flex-direction: row;

  display: flex;
  display:-webkit-flex;
  flex-direction: row;

  text-align: center;
  justify-content:center;
  align-items:center;
  font-family: 'Playfair Display',"CenturyGothic","HelveticaNeue","Helvetica Neue",Helvetica,Arial,Tahoma,sans-serif;
  width:100%;
  margin-bottom: 7%;
  
  @media (max-width: 756px){
    -webkit-box-orient: vertical;
  -webkit-box-flex:1;
  
  display:-moz-box;
  -moz-box-orient: vertical;
  -moz-box-flex:1;
  
  display:-o-box;
  -o-box-orient:vertical;
  -o-box-flex:1;

  display: -ms-flexbox;
  -ms-flex-direction: column;
  flex-direction: column;

  display: flex;
  display:-webkit-flex;
  flex-direction: column;
}`

  export const CommitmentDiv11 = styled.div`display:-webkit-box;
  -webkit-box-orient: vertical;
  -webkit-box-flex:1;
  
  display:-moz-box;
  -moz-box-orient: vertical;
  -moz-box-flex:1;
  
  display:-o-box;
  -o-box-orient:vertical;
  -o-box-flex:1;

  display: -ms-flexbox;
  -ms-flex-direction: column;
  flex-direction: column;

  display: flex;
  display:-webkit-flex;
  flex-direction: column;

  text-align: left;
  justify-content:left;
  align-items:left;
  font-family: 'Playfair Display',"CenturyGothic","HelveticaNeue","Helvetica Neue",Helvetica,Arial,Tahoma,sans-serif;
  width:30%;
  margin-left:7%;
  margin-right:2%;
  
  @media (max-width: 756px){
    margin:2%;
    width:80%;
  }`

  export const CommitmentDiv12 = styled.div`display:-webkit-box;
  -webkit-box-orient: vertical;
  -webkit-box-flex:1;
  
  display:-moz-box;
  -moz-box-orient: vertical;
  -moz-box-flex:1;
  
  display:-o-box;
  -o-box-orient:vertical;
  -o-box-flex:1;

  display: -ms-flexbox;
  -ms-flex-direction: column;
  flex-direction: column;

  display: flex;
  display:-webkit-flex;
  flex-direction: column;

  margin-right:2%;
  text-align: left;
  justify-content:left;
  align-items:left;
  font-family: 'Playfair Display',"CenturyGothic","HelveticaNeue","Helvetica Neue",Helvetica,Arial,Tahoma,sans-serif;
  width:30%;
  
  @media (max-width: 756px){
    margin:2%;
    width:80%;
  }`

  export const CommitmentDiv13 = styled.div`display:-webkit-box;
  -webkit-box-orient: vertical;
  -webkit-box-flex:1;
  
  display:-moz-box;
  -moz-box-orient: vertical;
  -moz-box-flex:1;
  
  display:-o-box;
  -o-box-orient:vertical;
  -o-box-flex:1;

  display: -ms-flexbox;
  -ms-flex-direction: column;
  flex-direction: column;

  display: flex;
  display:-webkit-flex;
  flex-direction: column;

  text-align: left;
  justify-content:left;
  align-items:left;
  font-family: 'Playfair Display',"CenturyGothic","HelveticaNeue","Helvetica Neue",Helvetica,Arial,Tahoma,sans-serif;
  width:30%;
  margin-right:7%;
  
   @media (max-width: 756px){
    margin:2%;
    width:80%;
  }`


  export const AboutLatestDiv = styled.div`display:-webkit-box;
  -webkit-box-orient: horizontal;
  -webkit-box-flex:1;
  
  display:-moz-box;
  -moz-box-orient: horizontal;
  -moz-box-flex:1;
  
  display:-o-box;
  -o-box-orient:horizontal;
  -o-box-flex:1;

  display: -ms-flexbox;
  -ms-flex-direction: row;
  flex-direction: row;

  display: flex;
  display:-webkit-flex;
  flex-direction: row;

  text-align: center;
  justify-content:center;
  align-items:center;
  font-family: 'Playfair Display',"CenturyGothic","HelveticaNeue","Helvetica Neue",Helvetica,Arial,Tahoma,sans-serif;
  width:90%;
  margin-left: 5%;
  margin-bottom: 7%;
  
  @media (max-width: 756px){
    -webkit-box-orient: vertical;
  -webkit-box-flex:1;
  
  display:-moz-box;
  -moz-box-orient: vertical;
  -moz-box-flex:1;
  
  display:-o-box;
  -o-box-orient:vertical;
  -o-box-flex:1;

  display: -ms-flexbox;
  -ms-flex-direction: column;
  flex-direction: column;

  display: flex;
  display:-webkit-flex;
  flex-direction: column;
}`

export const AboutLatestDiv1 = styled.div`display:-webkit-box;
  -webkit-box-orient: vertical;
  -webkit-box-flex:1;
  
  display:-moz-box;
  -moz-box-orient: vertical;
  -moz-box-flex:1;
  
  display:-o-box;
  -o-box-orient:vertical;
  -o-box-flex:1;

  display: -ms-flexbox;
  -ms-flex-direction: column;
  flex-direction: column;

  display: flex;
  display:-webkit-flex;
  flex-direction: column;

  text-align: left;
  justify-content:left;
  align-items:left;
  font-family: 'Playfair Display',"CenturyGothic","HelveticaNeue","Helvetica Neue",Helvetica,Arial,Tahoma,sans-serif;
  width:30%;
  height:100%;
  
  @media (max-width: 756px){
  width:80%;
}`




  