import styled from 'styled-components';

export const Header = styled.div`display:-webkit-box;
-webkit-box-orient: horizontal;
-webkit-box-flex:1;
  
display:-moz-box;
-moz-box-orient: horizontal;
-moz-box-flex:1;
  
display:-o-box;
-o-box-orient:horizontal;
-o-box-flex:1;

display: -ms-flexbox;
-ms-flex-direction: row;
flex-direction: row;

display: flex;
display:-webkit-flex;
flex-direction: row;

width:100%;
z-index:998;
position: -webkit-sticky;
position: sticky;
top: 0;
background: linear-gradient(to bottom right, #000533, #000533, #000533);

@media (max-width: 1165px){
  height: 5rem;
  background:#000533;
  z-index:0;
}`

// #181C4D


export const ModalDiv = styled.div`display:-webkit-box;
  -webkit-box-orient: horizontal;
  -webkit-box-flex:1;
    
  display:-moz-box;
  -moz-box-orient: horizontal;
  -moz-box-flex:1;
    
  display:-o-box;
  -o-box-orient:horizontal;
  -o-box-flex:1;

  display: -ms-flexbox;
  -ms-flex-direction: row;
  flex-direction: row;

  display: flex;
  display:-webkit-flex;
  flex-direction: row;
  position:absolute;
  z-index:222;`