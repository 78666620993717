import styled from 'styled-components';

export const DisplayDiv = styled.div` display:-webkit-box;
  -webkit-box-orient:horizontal;
  -webkit-box-flex:1;

  display:-moz-box;
  -moz-box-orient:horizontal;
  -moz-box-flex:1;

  display:-o-box;
  -o-box-orient:horizontal;
  -o-box-flex:1;

  padding: 1rem;
  margin: 2rem 0rem;
  border-radius:0.32em;
  border:1px solid #e4dede;
  background: #f1f1f1;

@media screen and (max-width: 754px){
  display:-webkit-box;
  -webkit-box-orient:vertical;
  -webkit-box-pack:center;
  -webkit-box-flex:1;

  display:-moz-box;
  -moz-box-orient:vertical;
  -moz-box-pack:center;
  -moz-box-flex:1;

  display:-o-box;
  -o-box-orient:vertical;
  -o-box-flex:1;
  -o-box-pack:center;

  margin: 0;`

export const DisplayDiv1 = styled.div`display:-webkit-box;
  display:-webkit-box;
  -webkit-box-orient:horizontal;
  -webkit-box-flex:1;

  display:-moz-box;
  -moz-box-orient:horizontal;
  -moz-box-flex:1;

  display:-o-box;
  -o-box-orient:horizontal;
  -o-box-flex:1;

  border-right: 1px solid #dbd7d7;
  margin-right:1.8em;
  
@media (max-width: 800px){
  display:-webkit-box;
  -webkit-box-orient:horizontal;
  -webkit-box-pack:center;
  -webkit-box-flex:1;

  display:-moz-box;
  -moz-box-orient:horizontal;
  -moz-box-pack:center;
  -moz-box-flex:1;

  display:-o-box;
  -o-box-orient:horizontal;
  -webkit-box-pack:center;
  -o-box-flex:1;

  width:100%;
  text-align:center;
  margin-bottom: 5%;
  border-right:none;
};`

export const DisplayTable = styled.table`border-bottom: 1px solid #d4cdcd;
width:50%;`