import styled from 'styled-components';

export const Firstdiv = styled.div`display:-webkit-box;
  -webkit-box-orient: horizontal;
  -webkit-box-flex:1;
    
  display:-moz-box;
  -moz-box-orient: horizontal;
  -moz-box-flex:1;
    
  display:-o-box;
  -o-box-orient:horizontal;
  -o-box-flex:1;

  display: -ms-flexbox;
  -ms-flex-direction: row;
  flex-direction: row;

  display: flex;
  display:-webkit-flex;
  flex-direction: row;
  font-family: 'Playfair Display',"CenturyGothic","HelveticaNeue","Helvetica Neue",Helvetica,Arial,Tahoma,sans-serif;

  width:100%; 
  justify-content: center;
  align-items: center;
  
  @media (max-width: 754px){
    display:-webkit-box;
    -webkit-box-orient: vertical;
    -webkit-box-flex:1;
    
    display:-moz-box;
    -moz-box-orient: vertical;
    -moz-box-flex:1;
    
    display:-o-box;
    -o-box-orient:vertical;
    -o-box-flex:1;
    
    display: -ms-flexbox;
    -ms-flex-direction: column;
    flex-direction: column;
    
    display: flex;
    display:-webkit-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
};`


export const Firstdiv1 = styled.div`display:-webkit-box;
-webkit-box-orient: vertical;
  -webkit-box-flex:1;
    
  display:-moz-box;
  -moz-box-orient: vertical;
  -moz-box-flex:1;
    
  display:-o-box;
  -o-box-orient:vertical;
  -o-box-flex:1;
  
  display: -ms-flexbox;
  -ms-flex-direction: column;
  flex-direction: column;
  
  display: flex;
  display:-webkit-flex;
  flex-direction: column;
  
  width:40%; 
  text-align: left;
  font-family: 'Playfair Display',"CenturyGothic","HelveticaNeue","Helvetica Neue",Helvetica,Arial,Tahoma,sans-serif;
  margin: 2%;
  justify-content: center;
  align-items: center;
  
  @media (max-width: 754px){
    width:90%; 
    margin-bottom: 4%;
  };`


  export const Firstdiv2 = styled.div`display:-webkit-box;
  -webkit-box-orient: horizontal;
  -webkit-box-flex:1;
    
  display:-moz-box;
  -moz-box-orient: horizontal;
  -moz-box-flex:1;
    
  display:-o-box;
  -o-box-orient:horizontal;
  -o-box-flex:1;
  
  display: -ms-flexbox;
  -ms-flex-direction: row;
  flex-direction: row;
  
  display: flex;
  display:-webkit-flex;
  flex-direction: row;
  
  width:60%;
  height:100%; 
  
  @media (max-width: 754px){
    width:90%; 
    margin-bottom: 4%;
  };`

