import styled from 'styled-components';

export const ContactDiv = styled.div` display:-webkit-box;
  display:-webkit-box;
  -webkit-box-orient:horizontal;

  display:-moz-box;
  -moz-box-orient:horizontal;

  display:-o-box;
  -o-box-orient:horizontal;

  display: -ms-flexbox;
  -ms-flex-direction: row;
  flex-direction: row;

  display: flex;
  flex-direction: row;
  width: 100%;
  min-height: 100%;
  align-items: center;
  justify-content: center;
  text-align: center;
  background:#181C4D;
  align-items: center;
  justify-content: start;
  text-align: center;
  color:#616161;
  margin-bottom: 10%;

@media (max-width: 786px){
  display:-webkit-box;
  -webkit-box-orient:vertical;

  display:-moz-box;
  -moz-box-orient:vertical;

  display:-o-box;
  -o-box-orient:vertical;

  display: -ms-flexbox;
  -ms-flex-direction: column;
  flex-direction: column;

  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  text-align: center;
  background:#ffffff;
  align-items: center;
  justify-content: center;
  text-align: center;
}`

export const ContactButton = styled.input`min-width: 75%;
  min-height:3rem;
  border-radius:0.4rem;
  background: #FFD700;
  color:#fff;
  border:none;
  font-size:1.1rem;
  color: #fff;
  margin:2%;
  width:95%;
  &:hover{
    opacity:.8;
}`

export const ContactTable = styled.table`display:-webkit-box;
  display:-webkit-box;
  -webkit-box-orient:vertical;

  display:-moz-box;
  -moz-box-orient:vertical;

  display:-o-box;
  -o-box-orient:vertical;

  display: -ms-flexbox;
  -ms-flex-direction: column;
  flex-direction: column;

  display: flex;
  flex-direction: column;
  width: 30%;
  height:50%;
  padding:0%;
  margin-top:4%;
  margin-left: 7%;
  margin-right:3%;
  color: #fff;
  background:#181C4D;
  
  @media (max-width: 786px){
    width: 100%;
    margin-top:0%;
    margin-left: 0%;
    margin-right:0%;
    text-align:center;
  }`

export const ContactForm = styled.form`display:-webkit-box;
  display:-webkit-box;
  -webkit-box-orient:vertical;

  display:-moz-box;
  -moz-box-orient:vertical;

  display:-o-box;
  -o-box-orient:vertical;

  display: -ms-flexbox;
  -ms-flex-direction: column;
  flex-direction: column;

  display: flex;
  flex-direction: column;
  width: 60%;
  height:80%;
  margin-top:4%;
  margin-right:3%;
  background:#f7f5f5;
  
  @media (max-width: 786px){
    text-align:center;
    justify-content:center;
    align-items:center;
    width: 90%;
  }`

export const ContactTextArea = styled.textarea`width:70%;
  height:60%;
  padding:0.7rem;
  font-size: $small-text;`



