import styled from 'styled-components';

export const WhatsAppImg = styled.img`position: fixed;
  position: fixed;
  z-index: 2147483003;
  bottom: 30px;
  right: 20px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  // background: #fe0000;
  cursor: pointer;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.06), 0 2px 32px 0 rgba(0, 0, 0, 0.16);
  animation: intercom-lightweight-app-launcher 250ms ease;
  display:none
@media (max-width: 1080px){
  display: block;
}`


// .whatsapp-img{
//   position: fixed;
//   z-index: 2147483003;
//   bottom: 30px;
//   right: 20px;
//   width: 50px;
//   height: 50px;
//   border-radius: 50%;
//   // background: #fe0000;
//   cursor: pointer;
//   box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.06), 0 2px 32px 0 rgba(0, 0, 0, 0.16);
//   animation: intercom-lightweight-app-launcher 250ms ease;
//   display:none
// };

// @media screen and (max-width: 1080px){
//   .whatsapp-img{
//     display: block;
//   };
// };
