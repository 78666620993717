const Categories = ["Event", "LifeStyle", "Travel", "Business",
                    "Interior", "Real Estate", "OutDoor", "Culinary",
                    "Smart Space", "Advisor", "Fabric", "Accessories"]

const BlogCategories = (Cate) => {
  const Categoriees = ["Event", "LifeStyle", "Travel", "Business",
                    "Interior", "Real Estate", "OutDoor", "Culinary",
                    "Smart Space", "Advisor", "Fabric", "Accessories"]
   if(!Array.isArray(Cate) || Cate === "undefined"){
    return "An Error Occured";
  }else if(Array.isArray(Cate)){
    let firstBucket = Categoriees.slice(0,6);
    let secondBucket = Categoriees.slice(6, );
    const getlastItem = "Real Estate";
    const getlastItem2 = "Accessories";
    for(let i=0; i <= Cate.length; i++){
       if(Cate[i] === getlastItem){
        return secondBucket;
       }else if(Cate[i] === getlastItem2){
        return firstBucket; 
       };
    };
  }else{
    return "Invalid Operation";
  };
};

export {Categories, BlogCategories, }

