const defaultState = {
  booking: false,                                                                     
};                            

const bookingReducer = (state = defaultState, action) => {
  switch(action.type){
    case 'SET_BOOKING_STATE':
      return {
        ...state,                              
        booking : action.booking
      };
    default:
      return state;
  };
};

export default bookingReducer;