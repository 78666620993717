const defaultState = {
  blogRead: false,
  articleid: 0,
  articleimg: '',
  author: '',
  title: '',
  currentArticle: '',                                                                     
};                            

const blogReducer = (state = defaultState, action) => {
  switch(action.type){
    case 'SET_BLOGREAD_STATE':
      return {
        ...state,                              
        blogRead : action.blogRead
      };
    case 'SET_ARTICLE_ID':
    return {
      ...state,                              
      articleid : action.articleid
    };
    case 'SET_ARTICLE_IMG':
      return {
        ...state,                              
        articleimg : action.articleimg
      };
    case 'SET_AUTHOR':
      return {
        ...state,                              
        author : action.author
      };
    case 'SET_TITLE':
      return {
        ...state,                              
        title : action.title
      };
    case 'SET_CURRENT_ARTICLE':
      return {
        ...state,                              
        currentArticle : action.currentArticle
      };
    default:
      return state;
  };
};

export default blogReducer;