import {createStore, combineReducers, applyMiddleware} from 'redux';
import thunk from 'redux-thunk';
import allCSSReducer from '../reducers/allCSS';
import verifyReducer from '../reducers/verification';
import bookingReducer from '../reducers/booking';
import categoryReducer from '../reducers/category';
import blogReducer from '../reducers/blog';

// export default () => createStore(
//   combineReducers({
    
// }), applyMiddleware(thunk));

const configureStore = () => createStore(
  combineReducers({
    allCSS: allCSSReducer,
    verification: verifyReducer,
    booking: bookingReducer,
    category: categoryReducer,
    blog: blogReducer,
}), applyMiddleware(thunk));


export default configureStore;

