import styled from 'styled-components';

export const BookingImg = styled.img`position: fixed;
  position: fixed;
  z-index: 2147483003;
  bottom: 30px;
  right: 50%;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  // background: #fe0000;
  cursor: pointer;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.06), 0 2px 32px 0 rgba(0, 0, 0, 0.16);
  animation: intercom-lightweight-app-launcher 250ms ease;
@media (max-width: 1080px){
  display: block;
}`