
const Staff = () => {
  return [
    {
      name: "Stephen Gala Omoefe",
      firstName: 'Stephen',
      StaffID: 57382760,
      Designation: "Technical Marketer",
      ContactNo: "07032225466",
      pictureString: "https://res.cloudinary.com/daw8l0o1v/image/upload/v1724889789/TSS/Staff/ucqh00l6ky6oywkrolsy.jpg",
    },
    {
      name: "Augustine Daguo Jennifer",
      firstName: "Jennifer",
      StaffID: 5738276,
      Designation: "Technical Marketer",
      ContactNo: "08161727762",
      pictureString: "https://res.cloudinary.com/daw8l0o1v/image/upload/v1724889789/TSS/Staff/c9573si1tkkzg0utjmvk.jpg",
    },
    {
      name: "Okechukwu",
      firstName:"Okechukwu",
      StaffID: 5738676,
      Designation: "Manager",
      ContactNo: "09027206322",
      pictureString: "https://res.cloudinary.com/daw8l0o1v/image/upload/v1724889789/TSS/Staff/k09vkyjv0wwwsrdvqbmq.jpg",
    },
    {
      name: "Okechukwu",
      firstName: "Okechukwu",
      fullName: "Okere Okechukwu Lucky",
      StaffID: 57386760,
      Designation: "Manager",
      ContactNo: "07038695989",
      pictureString: "https://res.cloudinary.com/daw8l0o1v/image/upload/v1724889789/TSS/Staff/k09vkyjv0wwwsrdvqbmq.jpg",
    },
    {
      name: "Patrick Emmanuel",
      firstName: "Patrick",
      fullName: "Patrick Emmanuel",
      StaffID: 57386761,
      Designation: "Electrician",
      ContactNo: "08060705884",
      pictureString: "https://res.cloudinary.com/daw8l0o1v/image/upload/v1724889788/TSS/Staff/zeigu3dtzwswetstazxv.jpg",
    },
    {
      name: "Dogara Joel",
      firstName: "Dogara",
      fullName: "Dogara Joel",
      StaffID: 57386763,
      Designation: "Logistics Officer",
      ContactNo: "09060023596",
      pictureString: "https://res.cloudinary.com/daw8l0o1v/image/upload/v1724889788/TSS/Staff/g3sxw33jicguz0os6bgk.jpg",
    },
    {
      name: "Victoria Adeola Owoeye",
      firstName: "Victoria",
      fullName: "Victoria Adeola Owoeye",
      StaffID: 8456372,
      Designation: "Admin",
      ContactNo: "08147220739",
      pictureString: "https://res.cloudinary.com/daw8l0o1v/image/upload/v1725523572/TSS/Staff/Victoria_oclzp1.jpg",
    },
    {
      name: "Adebamhe Frances",
      firstName: "Frances",
      fullName: "Adebamhe Frances",
      StaffID: 8456372,
      Designation: "Admin",
      ContactNo: "08135800280",
      pictureString: "https://res.cloudinary.com/daw8l0o1v/image/upload/v1726133988/TSS/Staff/Frances_dqyqle.jpg",
    },
  ];
};

export default Staff;