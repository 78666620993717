import styled from 'styled-components';

export const ContactDiv = styled.div`display:-webkit-box;
  -webkit-box-orient:vertical;
  -webkit-box-pack:center;
  -webkit-box-flex:1;

  display:-moz-box;
  -moz-box-orient:vertical;
  -moz-box-pack:center;
  -moz-box-flex:1;

  display:-o-box;
  -o-box-pack:center;
  -o-box-orient:vertical;
  -o-box-flex:1;
  font-family: 'Playfair Display',"CenturyGothic","HelveticaNeue","Helvetica Neue",Helvetica,Arial,Tahoma,sans-serif;

  width:100%;
  margin-bottom: 5%;
  background: #181C4D;

@media screen and (max-width: 1103px){

  margin: 0;
};`

  export const ContactForm = styled.form`display:-webkit-box;
  -webkit-box-orient:vertical;
  -webkit-box-flex:1;

  display:-moz-box;
  -moz-box-orient:vertical;
  -moz-box-flex:1;

  display:-o-box;
  -o-box-orient:vertical;
  -o-box-flex:1;
  font-family: 'Playfair Display',"CenturyGothic","HelveticaNeue","Helvetica Neue",Helvetica,Arial,Tahoma,sans-serif;

  align-items:center;
  text-align:center;
  width:100%;
  height: 100%;
  margin-bottom: 5%;

@media screen and (max-width: 1103px){

  margin: 0;
};`

export const ContactArrage = styled.div`display:-webkit-box;
  -webkit-box-orient:horizontal;
  -webkit-box-flex:1;

  display:-moz-box;
  -moz-box-orient:horizontal;
  -moz-box-flex:1;

  display:-o-box;
  -o-box-orient:horizontal;
  -o-box-flex:1;
  font-family: 'Playfair Display',"CenturyGothic","HelveticaNeue","Helvetica Neue",Helvetica,Arial,Tahoma,sans-serif;

  align-items:start;
  justify-content: center;
  text-align:center;
  width:100%;
  max-height: 10%;
  margin-bottom: 5%;

@media screen and (max-width: 1103px){

};`

  export const ContactArrage2 = styled.div`display:-webkit-box;
  -webkit-box-orient:vertical;
  -webkit-box-flex:1;

  display:-moz-box;
  -moz-box-orient:vertical;
  -moz-box-flex:1;

  display:-o-box;
  -o-box-orient:vertical;
  -o-box-flex:1;
  font-family: 'Playfair Display',"CenturyGothic","HelveticaNeue","Helvetica Neue",Helvetica,Arial,Tahoma,sans-serif;

  align-items:start;
  justify-content: start;
  text-align:start;
  width:100%;
  max-height: 10%;
  margin-bottom: 5%;

@media screen and (max-width: 1103px){
  margin-top: 4%;
};`

  export const ContactInput = styled.input`
  display:-webkit-box;
  -webkit-box-flex:1;

  display:-moz-box;
  -moz-box-flex:1;

  display:-o-box;
  -o-box-flex:1;
  font-family: 'Playfair Display',"CenturyGothic","HelveticaNeue","Helvetica Neue",Helvetica,Arial,Tahoma,sans-serif;

  max-width:40%;
  margin-top: 5%;
  margin-right: 4%;
  border-style: none;
  color: fff;
  background: #181C4D;

@media screen and (max-width: 1103px){
 
};`

  export const ContactInput2 = styled.input`
  display:-webkit-box;
  -webkit-box-flex:1;

  display:-moz-box;
  -moz-box-flex:1;

  display:-o-box;
  -o-box-flex:1;
  font-family: 'Playfair Display',"CenturyGothic","HelveticaNeue","Helvetica Neue",Helvetica,Arial,Tahoma,sans-serif;

  max-width:80%;
  margin-top: 5%;
  margin-right: 4%;
  border-style: none;
  color: fff;
  background: #181C4D;

@media screen and (max-width: 1103px){

};`

export const ContactButton = styled.input`display:-webkit-box;
-webkit-box-flex:1;

display:-moz-box;
-moz-box-flex:1;

display:-o-box;
-o-box-flex:1;
  width: 25%;
  margin-left: 33%;
  // padding: 2%;
  height: 90%;
  text-align: center;
  background: #FFC107;
  color:#fff;
  border-style: none;
  font-size: 1.27rem;
  border-radius: 14px;

  &:hover{
    opacity: .8;
  };

@media screen and (max-width: 1338px){
  margin-left: 2%;
  width: 90%
};`