import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const HeaderMenu = styled.div` display:-webkit-box;
-webkit-box-orient: vertical;
-webkit-box-flex:3;
  
display:-moz-box;
-moz-box-orient: vertical;
-moz-box-flex:3;
  
display:-o-box;
-o-box-orient:vertical;
-o-box-flex:3;

display: -ms-flexbox;
-ms-flex-direction: column;
flex-direction: column;

display: flex;
display:-webkit-flex;
flex-direction: column;

width:85%;
height: 6rem;
text-align: center;
align-items: center;
justify-content: center;
background:#000533;
color: #ffffff;
z-index:330;

@media (max-width: 1165px){
  width:40%;
  display:none;
  // visibility: hidden;
}`

export const Traverse = styled.a`text-decoration:none;
color:#ffffff;
font-family: 'Playfair Display',"CenturyGothic","HelveticaNeue","Helvetica Neue",Helvetica,Arial,Tahoma,sans-serif;
font-weight: 400;
&:hover{
  opacity: .75;
}`

export const Linked = styled(Link)`text-decoration:none;
color: #fff;
font-family: 'Playfair Display',"CenturyGothic","HelveticaNeue","Helvetica Neue",Helvetica,Arial,Tahoma,sans-serif;
font-weight: 400;
&:hover{
  opacity: .75;
}`

export const HeaderMenuTable = styled.table` display:-webkit-box;
-webkit-box-orient: vertical;
-webkit-box-flex:1;
  
display:-moz-box;
-moz-box-orient: vertical;
-moz-box-flex:1;
  
display:-o-box;
-o-box-orient:vertical;
-o-box-flex:1;

display: -ms-flexbox;
-ms-flex-direction: column;
flex-direction: column;

display: flex;
display:-webkit-flex;
flex-direction: column;

text-align: right;
align-items: right;
width:"100%",
borderCollapse: "collapse",
textAlign: "center",
z-index:332;`

export const ProjectsDropDownDiv = styled.div`display:-webkit-box;
  -webkit-box-orient: vertical;
  -webkit-box-flex:1;
    
  display:-moz-box;
  -moz-box-orient: vertical;
  -moz-box-flex:1;
    
  display:-o-box;
  -o-box-orient:vertical;
  -o-box-flex:1;

  display: -ms-flexbox;
  -ms-flex-direction: column;
  flex-direction: column;

  display: flex;
  display:-webkit-flex;
  flex-direction: column;

  width:10%;
  height: 2.5rem;
  position:fixed;
  margin-left:40%;
  z-index:11;
  opacity:0.001;
  top:auto;

  @media (max-width: 1165px){
    display:none;
  }`

  export const ProjectsDropDownDiv1 = styled.div`display:-webkit-box;
  -webkit-box-orient: vertical;
  -webkit-box-flex:1;
    
  display:-moz-box;
  -moz-box-orient: vertical;
  -moz-box-flex:1;
    
  display:-o-box;
  -o-box-orient:vertical;
  -o-box-flex:1;

  display: -ms-flexbox;
  -ms-flex-direction: column;
  flex-direction: column;

  display: flex;
  display:-webkit-flex;
  flex-direction: row;

  text-align: center;
  align-items: center;
  justify-content: center;
  opacity:1;
  width:25%;
  height: 9.5rem;
  position:fixed;
  margin-left:40%;
  margin-top:6rem;
  z-index:11;
  top:auto;
  background:#000533;
  color: #fff;
  font-family: 'Playfair Display',"CenturyGothic","HelveticaNeue","Helvetica Neue",Helvetica,Arial,Tahoma,sans-serif;
  font-size: 0.87rem;

  @media (max-width: 1165px){
    display:none;
  }`



  export const ShowRoomDropDownDiv = styled.div`display:-webkit-box;
  -webkit-box-orient: vertical;
  -webkit-box-flex:1;
    
  display:-moz-box;
  -moz-box-orient: vertical;
  -moz-box-flex:1;
    
  display:-o-box;
  -o-box-orient:vertical;
  -o-box-flex:1;

  display: -ms-flexbox;
  -ms-flex-direction: column;
  flex-direction: column;

  display: flex;
  display:-webkit-flex;
  flex-direction: column;

  width:10%;
  height: 6.5rem;
  background:#000533;
  position:fixed;
  margin-left:67%;
  z-index:11;
  opacity:.001;
  top:auto;

  @media (max-width: 1165px){
    display:none;
  }`

  export const ShowRoomDropDownDiv1 = styled.div`display:-webkit-box;
  -webkit-box-orient: vertical;
  -webkit-box-flex:1;
    
  display:-moz-box;
  -moz-box-orient: vertical;
  -moz-box-flex:1;
    
  display:-o-box;
  -o-box-orient:vertical;
  -o-box-flex:1;

  display: -ms-flexbox;
  -ms-flex-direction: column;
  flex-direction: column;

  display: flex;
  display:-webkit-flex;
  flex-direction: row;

  text-align: center;
  align-items: center;
  justify-content: center;
  background:red;
  opacity:1;
  width:25%;
  height: 9.5rem;
  position:fixed;
  margin-left:66.5%;
  margin-top:6rem;
  z-index:11;
  top:auto;
  background:#000533;
  color: #fff;
  font-family: 'Playfair Display',"CenturyGothic","HelveticaNeue","Helvetica Neue",Helvetica,Arial,Tahoma,sans-serif;
  font-size: 0.87rem,

  @media (max-width: 1165px){
    display:none;
  }`