import React from 'react';
import {
  BrowserRouter as Router,
  Route, Routes
} from 'react-router-dom';

import { createBrowserHistory } from "history";
import Home from '../components/HomePage';
import About from '../components/AboutUs';
import Interior from '../components/Interior';
import ContactUs from '../components/ContactUs';
import Facility from '../components/FacilityManagement/Facility';
import ShowRoom from '../components/ShowRoom';
import TSSBlogPage from '../components/TSSBlogPage';
import Utako from '../components/Blog/BlogContent/Utako';
import FabricCare from '../components/Blog/BlogContent/FabricCare';
import Sustainable from '../components/Blog/BlogContent/Sustainable';

//switch ensures only the first match is rendered
export const history = createBrowserHistory();

const AppRouter = () => (
  <Router history={history}>
    <div>
      <Routes> 
        <Route path="/" element={<Home />} exact={true} key="TripleSolutionsServicesHome"/>
        <Route path="/home" element={<Home />} exact={true} key="TripleSolutionsServicesHome1"/>
        <Route path="/about" element={<About />} exact={true} key="TripleSolutionsServicesAbout"/>
        <Route path="/interior" element={<Interior />} exact={true} key="TripleSolutionsServicesInterior"/>
        <Route path="/contact" element={<ContactUs />} exact={true} key="TripleSolutionsServicesContact"/>
        <Route path="/facility" element={<Facility />} exact={true} key="TripleSolutionsServicesFacility"/>
        <Route path="/projects" element={<About />} exact={true} key="TripleSolutionsServicesProjects"/>
        <Route path="/showroom" element={<ShowRoom />} exact={true} key="TripleSolutionsServicesShowRoom"/>
        <Route path="/blog" element={<TSSBlogPage />} exact={true} key="TripleSolutionsServicesBlog"/>
        {/* #Blog */}
        <Route path="/how-utako-shapes-up" element={<Utako />} exact={true} key="BlogUtako"/>
        <Route path="/fabric-care" element={<FabricCare />} exact={true} key="BlogFabricCare"/>
        <Route path="/creating-sustainable-work-space" element={<Sustainable />} exact={true} key="BlogSustainable"/>
      </Routes>
    </div>
  </Router>
);

export default AppRouter;
